import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import "./style.css";
import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import Button from "@mui/material/Button";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Patrocinadores from "../patrocinio/Patrocinadores";
import Conferencistas from "../conferencistas/Conferencistas";
import { Modal } from "@mui/material";
const admin_service = require("../../helpers/admin_service");
const helpers = require("../../helpers/helpers");

const Inicio = () => {
  const [contador] = useState(0);
  const [evento, setEvento] = useState({});
  const [patrocinadores, setPatrocinadores] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    height: "90vh",
    bgcolor: "transparent",

    p: 4,
  };
  useEffect(() => {
    admin_service
      .getData("/evento/view-by-hash/" + process.env.REACT_APP_EVT)
      .then((response_evt) => {
        admin_service
          .getData("/patrocinador/view-by-evento/" + process.env.REACT_APP_EVT)
          .then((response_patrocinador) => {
            setEvento(response_evt.data.response_database.result[0]);
            setPatrocinadores(response_patrocinador.data.response.result);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);
  return (
    <Box paddingTop={10}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack spacing={4} alignItems="center">
        <Stack spacing={5} alignItems="center" justifyContent="center">
          <Typography variant="h4" style={{ textAlign: "center" }}>
            <EventIcon /> &nbsp;28 de Octubre
          </Typography>
          <Button
            style={{
              backgroundColor: "#e5be65",
              color: "white",
              border: "1px solid transparent",
            }}
            variant="outlined"
            startIcon={<DriveFileRenameOutlineIcon />}
            onClick={() => {
              window.open(
                "https://ebg.edu.gt/inscripcion/988c050f2d18fc4c7de9a9c82d93d08b7100012fd86332bad22862e4f44fd516",
                "_blank"
              );
            }}
          >
            Inscríbete en Línea
          </Button>
        </Stack>

        {/*<video autoPlay muted loop style={{ width: "50%" }}>
          <source
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/2024+CLADIT+3.mp4"
            type="video/mp4"
          />
        </video>*/}
        <Stack alignItems="center" justifyContent="center" spacing={5}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ textAlign: "justify" }}
          >
            {/*<Grid xs={12} md={6} lg={6} p={3}>
              <Stack spacing={5}>
                <Button
                  onClick={handleTogglePlay}
                  startIcon={isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                  style={{
                    backgroundColor: "#397d51",
                    color: "white",
                    border: "1px solid transparent",
                  }}
                >
                  {isPlaying
                    ? "Click para pausar video"
                    : "Click para reproducir video"}
                </Button>
                <video ref={videoRef} controls style={{ width: "100%" }}>
                  <source
                    src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Video+Jorge+Gonzalez++2.mp4"
                    type="video/mp4"
                  />
                </video>
              </Stack>
            </Grid>*/}
            {evento.planos && (
              <img
                src={evento.planos}
                alt={evento.hash}
                style={{ width: "90%" }}
                loading="lazy"
                onClick={handleOpen}
              />
            )}
            <Grid xs={12} md={6} lg={6} p={3}>
              <Typography variant="h4">¿Qué es FOFIDE?</Typography>
              <Typography variant="p">
                <br />
                La Asociación Bancaria de Guatemala -ABG-, a través de sus
                iniciativas educativas, se dedica a fortalecer el conocimiento y
                las prácticas en torno a la gestión fiduciaria, la integración
                de nuevas tecnologías en los fideicomisos y el cumplimiento de
                normativas financieras. Este evento busca reunir a expertos y
                profesionales del sector para debatir y analizar los desafíos y
                oportunidades que enfrentan las instituciones financieras y los
                profesionales del derecho en estos ámbitos.
                <br />
                <br />
                Para ello, invita a participar en su&nbsp;
                <strong>
                  FORO INTERNACIONAL DE FIDEICOMISO Y DERECHO FINANCIERO 2024.
                </strong>
                &nbsp;Este foro está diseñado para profesionales, académicos, y
                cualquier persona interesada en la evolución del fideicomiso
                como herramienta financiera y legal. Durante el evento, se
                presentarán conferencias nacionales e internacionales, paneles
                de discusión, y se proporcionarán espacios para el análisis y la
                reflexión con expertos en temas como la constitución y
                administración de fideicomisos, la extinción y liquidación de
                fideicomisos vencidos, y la transformación digital en la gestión
                fiduciaria.
                <br />
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="center">
            <Grid xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
              <Typography variant="h4">HOTEL INTERCONTINENTAL</Typography>
              <Typography variant="h5">SALÓN ROBLE I</Typography>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4579.930217170129!2d-90.5170559242587!3d14.598112177136256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a3c538511d69%3A0x2428530068718505!2sReal%20InterContinental%20Guatemala!5e1!3m2!1ses!2sgt!4v1726181116858!5m2!1ses!2sgt"
                width="75%"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Grid>
            <Grid xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
              <img
                src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/2948f31d-211e-483e-9996-e31b16884233.png"
                style={{ width: "60%", loading: "lazy" }}
              />
            </Grid>
          </Grid>
          <ImageList cols={5}>
            <ImageListItem>
              <img
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/4cc22d5f-2296-47f7-abf9-faf88f90b0fb.png`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/a0291dee-6c06-4da1-b919-e7d519b6c7a1.png`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/5c066e0d-8538-493c-a81d-986140e09505.jpeg`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/caa4415f-38b4-4461-8791-c0d4b78f8e99.jpeg`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/a55fcd46-a4f0-4fcc-8ea1-7b35066e54d2.jpeg`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
          </ImageList>
        </Stack>
        <Conferencistas />
        <Grid container alignItems="center" justifyContent="center">
          <Grid xs={12} md={6} lg={6}>
            <img
              style={{ width: "100%" }}
              src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/IMG_7454+(2).jpg`}
              alt="cladit-img"
              loading="lazy"
            />
          </Grid>
          <Grid xs={12} md={6} lg={6}>
            <Stack spacing={2} style={{ padding: "6%" }}>
              <Typography variant="h5" style={{ textAlign: "center" }}>
                Dirigido a:
              </Typography>
              <Typography
                variant="p"
                style={{ textAlign: "left", fontSize: "0.9em" }}
              >
                <ul className="columns">
                  <li>Directores y Gerentes Generales</li>
                  <li>Directores de Cumplimiento y Riesgos</li>
                  <li>Consultores y Asesores Financieros</li>
                  <li>Fiduciarios y Administradores de Fideicomisos</li>
                  <li>
                    Abogados Especializados en Derecho Financiero y Fiduciario
                  </li>
                  <li>Auditores y Controladores Financieros</li>
                  <li>Profesionales en Gestión de Activos y Patrimonios</li>
                  <li>
                    Especialistas en Transformación Digital y Tecnologías
                    Financieras
                  </li>
                </ul>
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        {patrocinadores.length > 0 && (
          <Patrocinadores
            evt={process.env.REACT_APP_EVT}
            patrocinadores={patrocinadores}
          />
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <img
              src={evento.planos}
              alt={evento.hash}
              style={{ width: "100%", objectFit: "contain", height: "100%" }}
              loading="lazy"
            />
          </Box>
        </Modal>
      </Stack>
    </Box>
  );
};

export default Inicio;

{
  /*<Box component="section" className="video-section">
          <Grid container></Grid>
          <video autoPlay muted loop id="myVideo">
            <source
              src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/RESUMEN+CLADIT+2023.mp4"
              type="video/mp4"
            />
          </video>
  </Box>*/
}
